/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = () => {
    if (document.readyState === "complete" && document.URL.includes("/social") ) { 
        var i, e, d = document, s = "script";
        i = d.createElement("script");i.async = 1;
        i.src = "https://cdn.curator.io/published/f440d724-a203-4d20-9cb8-583585c48324.js";
        e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
    }
}